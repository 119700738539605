<template>
    <div class="bg-white rounded-lg p-20 mb-30">
        <div class="grid lg:grid-cols-2 gap-30">
            <!-- Phone -->
            <div class="flex items-center">
                <img
                    class="mr-22"
                    :src="require('@/assets/images/icons/phone.svg')"
                    :alt="$t('altPhoneIconText')"
                >
                <div class="flex flex-col">
                    <p class="font-lg-bold tracking-tight opacity-85 text-gray-700">{{ $t('caseDetailsPhoneText') }}</p>
                    <p
                        v-if="caseDetails.victim.phone"
                        class="p tracking-tight opacity-85 text-gray-700"
                    >{{caseDetails.victim.phone}}</p>
                    <p
                        class="p tracking-tight opacity-85 text-gray-700"
                        v-else
                    >{{$t('caseDetailsNoData')}}
                    </p>
                </div>
            </div>
            <!-- Family doctor -->
            <div class="flex items-center ">
                <img
                    class="mr-22"
                    :src="require('@/assets/images/icons/justice.svg')"
                    :alt="$t('altDoctorIconText')"
                >
                <div class="flex flex-col">
                    <p class="font-lg-bold tracking-tight opacity-85 text-gray-700">{{ $t('caseDetailsFamillyDoctor') }}</p>
                    <div v-if="caseDetails.victim.family_doctor_name">
                        <p class="p tracking-tight opacity-85 text-gray-700 mb-4">{{caseDetails.victim.family_doctor_name}}</p>
                        <p class="p tracking-tight opacity-85 text-gray-700">{{caseDetails.victim.family_doctor_phone}}</p>
                    </div>
                    <p
                        class="p tracking-tight opacity-85 text-gray-700"
                        v-else
                    >{{$t('caseDetailsNoData')}}
                    </p>
                </div>
            </div>
            <!-- Address -->
            <div class="flex items-center ">
                <img
                    class="mr-22"
                    :src="require('@/assets/images/icons/home.svg')"
                    alt=""
                >
                <div class="flex flex-col">
                    <p class="font-lg-bold tracking-tight opacity-85 text-gray-700">{{ $t('caseDetailsAddress') }}</p>
                    <p
                        v-if="caseDetails.victim.current_residence.county.name || caseDetails.victim.current_residence.city.name || caseDetails.victim.current_residence.address"
                        class="p tracking-tight opacity-85 text-gray-700"
                    >
                        {{caseDetails.victim.current_residence.county.name}}, {{caseDetails.victim.current_residence.city.name}}, {{caseDetails.victim.current_residence.address}}
                    </p>
                    <p
                        class="p tracking-tight opacity-85 text-gray-700"
                        v-else
                    >{{$t('caseDetailsNoData')}}
                    </p>
                </div>
            </div>
            <!-- Psychiatrist -->
            <div class="flex items-center">
                <img
                    class="mr-22"
                    :src="require('@/assets/images/icons/justice.svg')"
                    alt=""
                >
                <div class="flex flex-col">
                    <p class="font-lg-bold tracking-tight opacity-85 text-gray-700">{{ $t('caseDetailsPreviousPsihicConsult') }}</p>
                    <p
                        class="p tracking-tight opacity-85 text-gray-700"
                        v-if="caseDetails.victim.has_psychiatric_history"
                    >
                        {{caseDetails.victim.psychiatric_history}}
                    </p>
                    <p
                        class="p tracking-tight opacity-85 text-gray-700"
                        v-else
                    >{{$t('caseDetailsNoData')}}</p>
                </div>
            </div>
            <!-- Age/Status -->
            <div class="flex items-center justify-between">
                <div class="flex items-center">
                    <img
                        class="mr-22"
                        :src="require('@/assets/images/icons/ageIcon.svg')"
                        alt=""
                    >
                    <div class="flex flex-col">
                        <p class="font-lg-bold tracking-tight opacity-85 text-gray-700">{{ $t('caseDetailsAge') }}</p>
                        <p
                            v-if="caseDetails.victim.age"
                            class="p tracking-tight opacity-85 text-gray-700"
                        >{{caseDetails.victim.age}}&nbsp;{{$t('caseDetailsYears')}}</p>
                        <p
                            class="p tracking-tight opacity-85 text-gray-700"
                            v-else
                        >{{$t('caseDetailsNoData')}}</p>
                    </div>
                </div>
                <div class="flex items-center mr-30">
                    <img
                        class="mr-22"
                        :src="require('@/assets/images/icons/heart.svg')"
                        alt=""
                    >
                    <div class="flex flex-col">
                        <p class="font-lg-bold tracking-tight opacity-85 text-gray-700">{{ $t('caseDetailsStatus') }}</p>
                        <p
                            v-if="caseDetails.victim.marital_status.name"
                            class="p tracking-tight opacity-85 text-gray-700"
                        >{{caseDetails.victim.marital_status.name}}</p>
                        <p
                            class="p tracking-tight opacity-85 text-gray-700"
                            v-else
                        >{{$t('caseDetailsNoData')}}</p>
                    </div>
                </div>
            </div>
            <!-- Criminal Record -->
            <div class="flex items-center">
                <img
                    class="mr-22"
                    :src="require('@/assets/images/icons/justice.svg')"
                    alt=""
                >
                <div class="flex flex-col">
                    <p class="font-lg-bold tracking-tight opacity-85 text-gray-700">{{ $t('caseDetailsPreviousJusticeRecord') }}</p>
                    <p
                        class="p tracking-tight opacity-85 text-gray-700"
                        v-if="caseDetails.victim.has_criminal_records"
                    >
                        {{ caseDetails.victim.criminal_records }}
                    </p>
                    <p
                        class="p tracking-tight opacity-85 text-gray-70"
                        v-else
                    >{{$t('caseDetailsNoData')}}</p>
                </div>
            </div>
            <!-- Persons in care -->
            <div class="flex items-center">
                <img
                    class="mr-16"
                    :src="require('@/assets/images/icons/dependentsIcon.svg')"
                    alt=""
                >
                <div class="flex flex-col">
                    <p class="font-lg-bold tracking-tight opacity-85 text-gray-700">{{ $t('caseDetailsPersonsInCare') }}</p>
                    <div v-if="caseDetails.victim.has_dependents === 1">
                        <div v-if="caseDetails.victim.children_dependents_number">
                            <p
                                class="p tracking-tight opacity-85 text-gray-700 mb-4"
                                v-if="caseDetails.victim.children_dependents_number > 1"
                            >
                                {{caseDetails.victim.children_dependents_number}}&nbsp;{{$t('caseDetailsChildrensInCare')}}
                            </p>
                            <p
                                class="p tracking-tight opacity-85 text-gray-700 mb-4"
                                v-else
                            >
                                {{caseDetails.victim.children_dependents_number}}&nbsp;{{$t('caseDetailsChildrenInCare')}}
                            </p>
                        </div>
                        <div v-if="caseDetails.victim.teenagers_dependents_number">
                            <p
                                class="p tracking-tight opacity-85 text-gray-700 mb-4"
                                v-if="caseDetails.victim.teenagers_dependents_number > 1"
                            >
                                {{caseDetails.victim.teenagers_dependents_number}}&nbsp;{{$t('caseDetailsTeenagersInCare')}}
                            </p>
                            <p
                                class="p tracking-tight opacity-85 text-gray-700 mb-4"
                                v-else
                            >
                                {{caseDetails.victim.teenagers_dependents_number}}&nbsp;{{$t('caseDetailsTeenagerInCare')}}
                            </p>
                        </div>
                        <div v-if="caseDetails.victim.adults_dependents_number">
                            <p
                                class="p tracking-tight opacity-85 text-gray-700 mb-4"
                                v-if="caseDetails.victim.adults_dependents_number > 1"
                            >
                                {{caseDetails.victim.adults_dependents_number}}&nbsp;{{$t('caseDetailsAdultsInCare')}}
                            </p>
                            <p
                                class="p tracking-tight opacity-85 text-gray-700 mb-4"
                                v-else
                            >
                                {{caseDetails.victim.adults_dependents_number}}&nbsp;{{$t('caseDetailsAdultInCare')}}
                            </p>
                        </div>
                        <div v-if="caseDetails.victim.elderly_dependents_number">
                            <p
                                class="p tracking-tight opacity-85 text-gray-700 mb-4"
                                v-if="caseDetails.victim.elderly_dependents_number > 1"
                            >
                                {{caseDetails.victim.elderly_dependents_number}}&nbsp;{{$t('caseDetailsElderliesInCare')}}
                            </p>
                            <p
                                class="p tracking-tight opacity-85 text-gray-700 mb-4"
                                v-else
                            >
                                {{caseDetails.victim.elderly_dependents_number}}&nbsp;{{$t('caseDetailsElderlyInCare')}}
                            </p>
                        </div>
                    </div>
                    <p
                        class="p tracking-tight opacity-85 text-gray-700"
                        v-else
                    >{{$t('caseDetailsNoData')}}
                    </p>
                </div>
            </div>
            <!-- Show complete case -->
            <div class="flex items-center">
                <router-link
                    @click.native="eraseErrors"
                    :to="{ name: 'CaseEdit', params: { slug: `${caseDetails.id}`} }"
                >
                    <Button
                        class="button bg-purple-500 text-white hover:bg-purple-600 w-257"
                        role="button"
                        :text="$t('caseDetailsSeeCompleteFileBtnText')"
                    />
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/buttons/Button'
import { mapActions } from 'vuex'

export default {
    name: 'VictimDetails',
    props: ['caseDetails'],
    components: {
        Button
    },
    methods: {
        ...mapActions(['eraseErrors'])
    }
}
</script>
