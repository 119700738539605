<template>
    <div class="bg-white rounded-lg p-20">
        <p class="text-2xl tracking-tight opacity-85 text-bold font-titillium-bold text-gray-800 mb-10">{{ $t('caseDetailsResponsablesTitle') }}</p>
        <!-- <p class="mb-20">Acest caz a fost inregistrat de CEVA NUME DE ORGANIZATIE AICI.</p> -->
        <div
            class="flex"
            v-if="filteredResponsibles.length"
        >
            <div
                class="flex"
                v-for="(responsible,index) in filteredResponsibles"
                :key="index"
            >
                <div class="flex my-20">
                    <p class="w-36 h-36 flex items-center justify-center ml-10 rounded-full bg-purple-50 text-purple-600 font-titillium-bold font-bold">
                        {{responsible.acronym}}
                    </p>
                </div>
            </div>
        </div>
        <p
            class="p tracking-tight opacity-85 text-gray-700"
            v-else
        >{{ $t('alocateToSeServicesText') }}</p>
    </div>
</template>

<script>
export default {
    name: 'ResponsiblesList',
    props: ['caseDetails'],

    data () {
        return {
            filteredResponsibles: []
        }
    },

    /** Watch for responsibeles added in services */
    watch: {
        'caseDetails.services': {
            handler: function () {
                const newServicesArray = []
                for (const key in this.caseDetails.services) {
                    if (Object.hasOwnProperty.call(this.caseDetails.services, key)) {
                        const element = this.caseDetails.services[key]
                        newServicesArray.push(...element.responsibles)
                    }
                }
                const ids = newServicesArray.map(newResponsibles => newResponsibles.id)
                this.filteredResponsibles = newServicesArray.filter(({ id }, index) => !ids.includes(id, index + 1))
            },
            deep: true
        }
    }
}
</script>
