<template>
    <div class="grid lg:grid-cols-12 gap-30 mt-30 mx-30">
        <div class="col-span-6 flex items-center">
            <!-- Victim name and case number -->
            <div class="flex font-4xl">
                <h1 class="text-gray-800 opacity-85 font-4xl font-titillium-bold">{{ caseDetails.victim.first_name }}&nbsp;{{ caseDetails.victim.last_name }}</h1>
                <span class="ml-10 text-purple-500">#{{caseDetails.id}}</span>
                <span v-if="caseDetails.status === 'draft'" class="ml-10 text-gray-800 opacity-85 font-titillium-bold">- {{ $t('tableStatusDraft') }}</span>
                <span v-if="caseDetails.status === 'registered'" class="ml-10 text-gray-800 opacity-85 font-titillium-bold">- {{ $t('tableStatusRegistered') }}</span>
                <span v-if="caseDetails.status === 'in progress'" class="ml-10 text-gray-800 opacity-85 font-titillium-bold">- {{ $t('tableStatusInProgress') }}</span>
                <span v-if="caseDetails.status === 'archived'" class="ml-10 text-gray-800 opacity-85 font-titillium-bold">- {{ $t('tableStatusInArchived') }}</span>
            </div>
        </div>
        <!-- Archive case and success box-->
        <div class="col-span-6 flex justify-end items-center relative">
            <Button
                @click.native="onArchiveCase"
                v-if="userTypeManager && caseDetails.status !== 'archived'"
                class="button bg-gray-500 hover:bg-gray-600 text-white w-auto md:w-257 px-20 md:px-0"
                role="button"
                :text="$t('caseDetailsArchiveBtnText')"
                :disabled="archiveCaseLoading"
                :spinning="archiveCaseLoading"
            />
            <Button
                @click.native="onUnarchiveCase"
                v-if="userTypeManager && caseDetails.status === 'archived'"
                class="button bg-gray-500 hover:bg-gray-600 text-white w-auto md:w-257 px-20 md:px-0"
                role="button"
                :text="$t('caseDetailsUnarchiveBtnText')"
                :disabled="unarchiveCaseLoading"
                :spinning="unarchiveCaseLoading"
            />
            <!-- Confirmation box for success messages -->
            <div
                class="fixed top-20 right-30 z-10000"
                ref="successBoxContainer"
            >
            </div>
        </div>
        <div class="col-span-12 xl:col-span-8">
            <!-- Victim information -->
            <VictimDetails :caseDetails="caseDetails" />
            <!-- Services -->
            <div class="bg-white rounded-lg mb-30">
                <!-- Services title and button -->
                <div class="flex justify-between items-center mb-20 px-20 pt-20">
                    <p class="text-2xl tracking-tight opacity-85 text-bold font-titillium-bold text-gray-800">{{ $t('caseDetailsServicesTitle') }}</p>
                    <Button
                        v-if="!addService && userTypeManager && caseDetails.status !== 'archived' && caseDetails.status !== 'draft'"
                        @btnClick="addServices"
                        class="button bg-purple-500 hover:bg-purple-600 text-white w-auto px-30"
                        role="button"
                        :text="$t('caseDetailsServicesAddServiceBtnText')"
                    />
                </div>
                <!-- Services table condition-->
                <v-table
                    class="table"
                    :data="caseDetails.services"
                    :currentPage.sync="currentPageServices"
                    :pageSize="pageSizeServices"
                    @totalPagesChanged="totalPagesServices = $event"
                    :hideSortIcons="true"
                >
                    <!-- Services columns name -->
                    <thead
                        class="thead"
                        slot="head"
                    >
                        <!-- Service column -->
                        <th class="th md:w-1/5 lg:w-1/5 xl:w-1/5">
                            <div class="th-out">
                                {{ $t('caseDetailsServicesTableColumn_1') }}
                            </div>
                        </th>
                        <!-- responsibles -->
                        <th class="th md:w-1/5 lg:w-1/5 xl:w-1/5">
                            <div class="th-out">
                                {{ $t('caseDetailsServicesTableColumn_2') }}
                            </div>
                        </th>
                        <!-- Disponibility -->
                        <th class="th md:w-1/5 lg:w-1/5 xl:w-1/5">
                            <div class="th-out">
                                {{ $t('caseDetailsServicesTableColumn_3') }}
                            </div>
                        </th>
                        <!-- Done -->
                        <th class="th md:w-1/5 lg:w-1/5 xl:w-1/5">
                            <div class="th-out">
                                {{ $t('caseDetailsServicesTableColumn_4') }}
                            </div>
                        </th>
                        <!-- Options -->
                        <th class="th md:w-1/5 lg:w-1/5 xl:w-1/5"></th>
                    </thead>
                    <!-- Table body -->
                    <tbody
                        id="table_body"
                        class="tbody"
                        slot="body"
                        slot-scope="{displayData}"
                    >
                        <!-- Service row -->
                        <tr
                            class="border-b"
                            v-for="(service, index) in displayData"
                            :key="index"
                            :class="{editing: service === editedServiceRow}"
                            v-cloak
                        >
                            <!-- Service row name -->
                            <td>
                                <div class="flex items-center">
                                    <div class="flex items-center">
                                        <span class="th lg:hidden mr-7">{{ $t('caseDetailsServicesTableColumn_1') }}:</span>
                                        {{service.name}}
                                    </div>
                                </div>
                            </td>
                            <!-- Service row responsibles -->
                            <td>
                                <div class="flex items-center">
                                    <span class="th lg:hidden mr-7">{{ $t('caseDetailsServicesTableColumn_2') }}:</span>
                                    <!-- View responsibles -->
                                    <div class="flex items-center">
                                        <p
                                            v-for="responsible in service.responsibles"
                                            :key="responsible.id"
                                            class="w-36 h-36 flex items-center justify-center ml-4 rounded-full bg-purple-50 text-purple-600 font-titillium-bold font-bold"
                                            :class="{view: !updateDone}"
                                        >
                                            {{responsible.acronym}}
                                        </p>
                                    </div>

                                    <!-- Edit responsibles -->
                                    <div
                                        class="edit flex flex-col items-center w-full mr-20 md:mr-0"
                                        v-if="service.can_edit && !updateDone"
                                    >
                                        <label class="flex"></label>
                                        <Multiselect
                                            class="rounded border w-full"
                                            :class="[errors['responsibles.0.id'] ? 'border-red-500' : 'border-gray-200']"
                                            v-model="service.responsibles"
                                            :options="allMembers"
                                            label="acronym"
                                            track-by="acronym"
                                            :multiple="true"
                                            :placeholder="$t('servicesFieldPlaceholder')"
                                            :deselectLabel="$t('selectRemoveOptionText')"
                                            :selectLabel="$t('selectOptionText')"
                                            :selectedLabel="$t('selectOptionSelectedText')"
                                            :selectGroupLabel="$t('selectGroupOptionsText')"
                                            :deselectGroupLabel="$t('selectRemoveGroupOptionsText')"
                                        >
                                            <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                                            <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                                            <span slot="maxElements">{{$t('selectMaxElemenetsText')}}</span>
                                        </Multiselect>
                                        <p
                                            v-if="errors['responsibles.0.id']"
                                            class="text-red-500 small mt-4"
                                        >
                                            {{errors['responsibles.0.id'][0]}}
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <!-- Service row hours available -->
                            <td>
                                <div class="flex items-center">
                                    <span class="th lg:hidden mr-7">{{ $t('caseDetailsServicesTableColumn_3') }}:</span>
                                    <!-- View hours available -->
                                    <p :class="{view: !updateDone}">
                                        <span class="font-lg-bold text-purple-600">
                                            {{service.allocated_amount}}
                                        </span>
                                        <span class="small-bold text-gray-800">{{service.measurement_unit}}/{{ $t('measurementUnitText') }}</span>
                                    </p>
                                    <!-- Edit hours available -->
                                    <Input
                                        :error="errors.amount"
                                        v-if="service.can_edit && !updateDone"
                                        class="edit w-full mr-20 md:mr-0"
                                        :class="[errors.amount ? 'mb-4' : '']"
                                        v-model="service.allocated_amount"
                                        placeholder="Aloca ore"
                                        type="text"
                                    />
                                </div>
                            </td>
                            <!-- Service row hours done -->
                            <td>
                                <div class="flex items-center">
                                    <span class="th lg:hidden mr-7">{{ $t('caseDetailsServicesTableColumn_3') }}:</span>
                                    <!-- View row hours done -->
                                    <p :class="{view: updateDone}">
                                        <span
                                            class="font-lg-bold"
                                            :class="[service.reported_amount > service.allocated_amount ? 'text-red-500' : 'text-green-500']"
                                        >
                                            {{service.reported_amount}}
                                        </span>
                                        <span class="small-bold text-gray-800">{{service.measurement_unit}}/{{ $t('measurementUnitText') }}</span>
                                    </p>
                                    <div>
                                        <!-- Guidance for staff -->
                                        <label v-if="editedServiceRow === service && updateDone">{{ $t('selectUmNumberText') }}</label>
                                        <!-- Edit row hours done -->
                                        <Input
                                            v-if="updateDone"
                                            class="edit"
                                            :error="errors.value"
                                            v-model="hoursDoneUpdateNumber"
                                            :placeholder="$t('addHoursText')"
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </td>
                            <!-- Service row options -->
                            <td class="">
                                <!-- View state buttons for delete and edit -->
                                <div class="view flex lg:items-center lg:justify-center">
                                    <img
                                        v-if="service.can_edit && caseDetails.status !== 'archived'"
                                        @click="editServiceDoneData(service)"
                                        class="mr-20 cursor-pointer"
                                        :src="require('@/assets/images/icons/edit.svg')"
                                        alt="edit hours done icon"
                                    >
                                    <img
                                        v-if="userTypeManager && caseDetails.status !== 'archived'"
                                        @click="editServiceRowData(service)"
                                        class="mr-20 cursor-pointer"
                                        :src="require('@/assets/images/icons/cog.svg')"
                                        alt="edit icon"
                                    >
                                    <img
                                        @click="openDeleteServiceModal(service)"
                                        v-if="userTypeManager && caseDetails.status !== 'archived'"
                                        class="mr-20 cursor-pointer"
                                        :src="require('@/assets/images/icons/close.svg')"
                                        alt=""
                                    >
                                </div>
                                <!-- Edit state hidden form save button and cancel -->
                                <div class="edit">
                                    <div class="flex md:items-center md:justify-center">
                                        <!-- Hidden form -->
                                        <form @submit.prevent="onSubmit(service.id, service.name, service.responsibles, service.allocated_amount, service.reported_amount, service.measurement_unit, service.can_edit, service.total, service.type)">
                                            <input
                                                v-model="service.name"
                                                hidden
                                                ref="serviceName"
                                            >
                                            <div style="display:none">
                                                <Multiselect
                                                    ref="val"
                                                    v-model="service.responsibles"
                                                    :options="caseDetails.responsibles"
                                                    label="acronym"
                                                    track-by="acronym"
                                                    :multiple="true"
                                                    :placeholder="$t('servicesFieldPlaceholder')"
                                                    :deselectLabel="$t('selectRemoveOptionText')"
                                                    :selectLabel="$t('selectOptionText')"
                                                    :selectedLabel="$t('selectOptionSelectedText')"
                                                    :selectGroupLabel="$t('selectGroupOptionsText')"
                                                    :deselectGroupLabel="$t('selectRemoveGroupOptionsText')"
                                                >
                                                    <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                                                    <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                                                    <span slot="maxElements">{{$t('selectMaxElemenetsText')}}</span>
                                                </Multiselect>
                                            </div>
                                            <input
                                                v-model="service.allocated_amount"
                                                hidden
                                                ref="serviceAvailable"
                                            >
                                            <input
                                                v-model="service.reported_amount"
                                                hidden
                                                ref="serviceDone"
                                            >
                                            <label class="flex"></label>
                                            <Button
                                                v-if="!updateDone"
                                                class="button bg-purple-500 hover:bg-purple-600 text-white small w-auto px-20"
                                                role="submit"
                                                :text="$t('saveButtonText')"
                                                :disabled="updateAllocatedServiceLoading"
                                                :spinning="updateAllocatedServiceLoading"
                                            />
                                        </form>
                                        <form @submit.prevent="onUpdateHoursDone(service.id)">
                                            <input
                                                v-model="hoursDoneUpdateNumber"
                                                hidden
                                                ref="serviceDone"
                                            >
                                            <label class="flex"></label>
                                            <Button
                                                v-if="updateDone"
                                                class="button bg-purple-500 hover:bg-purple-600 text-white small w-auto px-20"
                                                role="submit"
                                                :text="$t('saveButtonText')"
                                                :disabled="updateHoursDoneLoading"
                                                :spinning="updateHoursDoneLoading"
                                            />
                                        </form>
                                        <!-- Cancel button -->
                                        <img
                                            @click="viewServiceRowData"
                                            class="ml-20 cursor-pointer"
                                            :src="require('@/assets/images/icons/close.svg')"
                                            alt=""
                                        >
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </v-table>
                <!-- Pagination -->
                <div
                    v-if="caseDetails.services.length"
                    class="flex items-center justify-between mt-30 pb-20"
                >
                    <div class="pl-10 p text-gray-800">{{pageServices}}-{{pageServicesSizeIncrement}} {{ $t('fromText') }} {{caseDetails.services.length}} {{ caseDetails.services.length > 1 ? $t('fromServicesText') : $t('fromServicesTextSingle')}}</div>
                    <div>
                        <smart-pagination
                            :currentPage.sync="currentPageServices"
                            :totalPages="totalPagesServices"
                        />
                    </div>
                </div>
                <div
                    v-else
                    class="bg-white rounded-lg shadow mb-30 p-20"
                >
                    <p class="text-2xl opacity-85 tracking-tight text-gray-800 font-bold font-titillium-bold mb-20 md:mb-0">{{$t('noRecordsText')}}</p>
                </div>
                <!-- Form for repetable add services row condition v-if: !caseDetails.case_services.length-->
                <div v-if="addService">
                    <div
                        class="mt-20 border-t-2 border-gray-300"
                        v-if="!caseDetails.services.length"
                    ></div>
                    <div
                        class="grid lg:grid-cols-3 gap-30 p-30 border-b-2 border-gray-300"
                        v-for="(slot,index) in addservices"
                        :key="index"
                    >
                        <div>
                            <label class="flex">{{ $t('selectServiceText') }}</label>
                            <Multiselect
                                class="rounded border"
                                :class="[errors['services.' + index + '.service_id'] ? 'border-red-500' : 'border-gray-200']"
                                v-model="slot.service"
                                :options="availableServices"
                                label="name"
                                track-by="name"
                                :placeholder="$t('servicesFieldPlaceholder')"
                                :deselectLabel="$t('selectRemoveOptionText')"
                                :selectLabel="$t('selectOptionText')"
                                :selectedLabel="$t('selectOptionSelectedText')"
                            >
                                <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                                <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                            </Multiselect>
                            <p
                                v-if="errors['services.' + index + '.service_id']"
                                class="text-red-500 small mt-4"
                            >
                                {{errors['services.' + index + '.service_id'][0]}}
                            </p>
                        </div>
                        <div>
                            <label class="flex">{{ $t('selectResponsableText') }}</label>
                            <Multiselect
                                class="rounded border"
                                :class="[errors['services.' + index + '.responsibles.0.id'] ? 'border-red-500' : 'border-gray-200']"
                                v-model="slot.responsibles"
                                :options="allMembers"
                                :multiple="true"
                                label="name"
                                track-by="id"
                                :placeholder="$t('servicesFieldPlaceholder')"
                                :deselectLabel="$t('selectRemoveOptionText')"
                                :selectLabel="$t('selectOptionText')"
                                :selectedLabel="$t('selectOptionSelectedText')"
                            >
                                <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                                <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                            </Multiselect>
                            <p
                                v-if="errors['services.' + index + '.responsibles.0.id']"
                                class="text-red-500 small mt-4"
                            >
                                {{errors['services.' + index + '.responsibles.0.id'][0]}}
                            </p>
                        </div>
                        <div>
                            <div class="flex items-center">
                                <Input
                                    v-model="slot.amount"
                                    :error="errors['services.' + index + '.amount']"
                                    :label="$t('addHoursText')"
                                    :placeholder="$t('addHoursText')"
                                    type="text"
                                    :name="slot.count"
                                />
                                <img
                                    v-if="index > 0"
                                    @click="removeField(index)"
                                    class="ml-22 mt-22 cursor-pointer"
                                    :src="require('@/assets/images/icons/close.svg')"
                                    alt=""
                                >
                            </div>
                        </div>
                    </div>

                    <div class="grid lg:grid-cols-2 gap-30 p-30">
                        <div>
                            <Button
                                v-if="addService"
                                @btnClick="addServices"
                                class="button bg-gray-500 hover:bg-gray-600 text-white w-auto px-30"
                                role="button"
                                :text="$t('cancelButtonText')"
                            />
                        </div>
                        <div class="flex justify-end">
                            <Button
                                @btnClick="addAnother"
                                class="button bg-purple-500 hover:bg-purple-600 text-white w-auto px-30"
                                role="button"
                                text="Adauga mai multe"
                            />
                            <Button
                                @btnClick="addServicesToCase()"
                                class="button bg-purple-500 hover:bg-purple-600 text-white w-auto px-30 ml-20"
                                role="button"
                                :text="$t('addServiceText')"
                                :disabled="addServicesToCaseLoading"
                                :spinning="addServicesToCaseLoading"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <!-- Documents -->
            <div class="bg-white rounded-lg mb-30 pb-20">
                <!-- Documents title and button -->
                <div class="flex flex-col md:flex-row md:items-center justify-between p-20">
                    <p class="text-2xl tracking-tight opacity-85 text-bold font-titillium-bold text-gray-800">{{ $t('caseDetailsDocumentsTitle') }}</p>
                    <Button
                        v-if="caseDetails.status !== 'archived' && caseDetails.status !== 'draft'"
                        @btnClick="showModal = true"
                        class="button bg-purple-500 hover:bg-purple-600 text-white w-257 mt-22 md:mt-0"
                        role="button"
                        :text="$t('caseDetailsDocumentsAddDocumentBtnText')"
                    />
                </div>
                <!-- Documents table -->
                <v-table
                    v-if="caseDetails.files.length"
                    class="table mb-30 md:mb-0"
                    :data="caseDetails.files"
                    :currentPage.sync="currentPageFiles"
                    :pageSize="pageSizeFiles"
                    @totalPagesChanged="totalPagesFiles = $event"
                    :hideSortIcons="true"
                >
                    <!-- Documents columns name -->
                    <thead
                        class="thead"
                        slot="head"
                    >
                        <!-- Document column -->
                        <th>
                            <v-th
                                class="flex items-center justify-between flex-row-reverse th"
                                sortKey="name"
                            >
                                {{ $t('caseDetailsDocumentsTableColumn_1') }}
                            </v-th>
                        </th>
                        <!-- Registered column -->
                        <th>
                            <v-th
                                class="flex default-arrows items-center justify-between flex-row-reverse th"
                                sortKey="created_at_reversed"
                                defaultSort="desc"
                            >
                                {{ $t('caseDetailsDocumentsTableColumn_2') }}
                            </v-th>
                        </th>
                        <!-- Options -->
                        <th></th>
                    </thead>
                    <tbody
                        id="doc_table_body"
                        class="tbody"
                        slot="body"
                        slot-scope="{displayData}"
                    >
                        <tr
                            class="border-b"
                            v-for="file in displayData"
                            :key="file.id"
                        >
                            <!-- Documents row -->
                            <td class="flex items-center">
                                <div class="flex items-center">
                                    <span class="th lg:hidden mr-7">{{ $t('caseDetailsDocumentsTableColumn_1') }}:</span>
                                    <div
                                        v-if="file.type === 'file' || file.type === 'case-form'"
                                        class="flex"
                                    >
                                        <img
                                            class="mr-10"
                                            :src="require('@/assets/images/icons/file.svg')"
                                            alt=""
                                        >
                                        <p class="text-purple-600">{{file.name}}</p>
                                    </div>
                                    <span
                                        class="flex underline text-purple-600"
                                        style="cursor:pointer;"
                                        v-else
                                        @click="editFromPdf(file.id, caseDetails.id)"
                                    >
                                        <img
                                            class="mr-10 cursor-pointer"
                                            :src="require('@/assets/images/icons/form.svg')"
                                            alt=""
                                        >
                                        <p class="text-purple-600">{{file.name}}</p>
                                    </span>
                                </div>
                            </td>
                            <!-- Registerd row  -->
                            <td>
                                <div class="flex items-center">
                                    <span class="th lg:hidden mr-7">{{ $t('caseDetailsDocumentsTableColumn_2') }}:</span>
                                    {{file.created_at}}
                                </div>
                            </td>
                            <!-- Options row -->
                            <td class="flex lg:justify-center lg:items-center">
                                <div @click='downloadOne(file)'>
                                    <img
                                        class="mr-40 cursor-pointer"
                                        :src="require('@/assets/images/icons/download.svg')"
                                        alt=""
                                    >
                                </div>
                                <img
                                    class="cursor-pointer"
                                    @click="openDeleteModal(file)"
                                    :class="[file.can_edit && caseDetails.status !== 'archived' && file.type !== 'case-form' ? 'visible' : 'invisible']"
                                    :src="require('@/assets/images/icons/close.svg')"
                                    alt=""
                                >
                            </td>
                        </tr>
                    </tbody>
                </v-table>
                <p
                    v-else
                    class="px-20 pb-20 tracking-tight opacity-85 font-titillium-normal"
                >{{ $t('noFilesForCaseText') }}
                </p>
                <div
                    v-if="caseDetails.files.length"
                    class="flex md:justify-end md:mt-30 ml-20 md:ml-0 pr-20"
                >
                    <Button
                        @click.native="downloadAll"
                        class="button bg-purple-500 hover:bg-purple-600 text-white w-auto px-30"
                        role="button"
                        :text="$t('caseDetailsDocumentsDownloadDocumentsBtnText')"
                        :disabled="downloadAllLoading"
                        :spinning="downloadAllLoading"
                    />
                </div>
                <!-- Pagination -->
                <div
                    v-if="caseDetails.files.length"
                    class="flex items-center justify-between mt-30"
                >
                    <div class="pl-10 p tracking-tight opacity-85 text-gray-800">{{pageFiles}}-{{pageFilesSizeIncrement}} {{ $t('fromText') }} {{caseDetails.files.length}} {{ caseDetails.files.length > 1 ? $t('fromFilesText') : $t('fromFilesTextSingle') }}</div>
                    <div>
                        <smart-pagination
                            :currentPage.sync="currentPageFiles"
                            :totalPages="totalPagesFiles"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-span-12 xl:col-span-4">
            <!-- Aggressor information -->
            <AggressorDetails :caseDetails="caseDetails" />
            <!-- Responsibles -->
            <ResponsiblesList :caseDetails="caseDetails" />
        </div>
        <Modal
            width='w-768'
            v-if="showModal"
            @close="closeAddFilesModal"
            @closeOnEscPressed="closeAddFilesModal"
            class="px-30 md:px-0"
        >
            <div slot="title">
                <h2 class="font-4xl font-titillium-bold opacity-85 text-gray-800">{{ $t('filesAddFileTitle') }}</h2>
            </div>
            <p
                class="my-30 p text-gray-800 tracking-tight opacity-85"
                slot="subtitle"
            >
                {{ $t('filesAddFileSubtitle') }}
            </p>
            <div slot="body">
                <div
                    class="grid lg:grid-cols-2 gap-30"
                    v-if="this.filesToBeUploaded.length === 0"
                >
                    <form class="bg-gray-200 p-30 rounded">
                        <div
                            class="bg-gray-200 borde"
                            @dragover="dragover"
                            @drop="drop"
                        >
                            <input
                                type="file"
                                multiple
                                name="fields[assetsFieldHandle][]"
                                id="assetsFieldHandle"
                                class="w-px h-px opacity-0 overflow-hidden absolute"
                                @change="fileUpload"
                                ref="file"
                            />

                            <label
                                v-if="!this.filesToBeUploaded.length"
                                for="assetsFieldHandle"
                                class="block cursor-pointer"
                            >
                                <p class="text-center tracking-tight opacity-85 text-lg2 font-bold font-titillium-bold text-gray-800">{{ $t('uploadFileHereText') }}</p>
                                <img
                                    class="my-30 mx-auto cursor-pointer"
                                    :src="require('@/assets/images/icons/upload.svg')"
                                    alt=""
                                >

                                <p class="text-center tracking-tight opacity-85 small text-gray-800">{{ $t('filesAcceptedText') }}</p>
                            </label>
                        </div>
                    </form>
                    <div class="bg-gray-200 p-30 rounded">
                        <p class="text-center tracking-tight opacity-85 text-lg2 font-bold font-titillium-bold text-gray-800">{{ $t('useSablonTitle') }}</p>
                        <img
                            @click="OpenSablonForms"
                            class="my-30 mx-auto cursor-pointer"
                            :src="require('@/assets/images/icons/upload_sablon.svg')"
                            alt=""
                        >
                        <p class="text-center tracking-tight opacity-85 small text-gray-800">{{ $t('useSablonSubtitle') }}</p>
                    </div>
                </div>
                <div
                    class="bg-gray-200 p-30 rounded"
                    v-if="this.filesToBeUploaded.length"
                    v-cloak
                >
                    <ul>
                        <li
                            v-for="(file, index) in filesToBeUploaded"
                            :key="index"
                        >
                            <div class="flex items-center justify-between mb-10">
                                <p>{{ file.name }}</p>
                                <img
                                    class="cursor-pointer"
                                    v-if="fileIsUpload"
                                    @click="deleteUploadedFile(index)"
                                    :src="require('@/assets/images/icons/close.svg')"
                                    alt=""
                                >
                            </div>
                            <div class="w-full h-10 rounded-full bg-gray-500 relative mb-20">
                                <div
                                    :id="`fileProgressBar-${index}`"
                                    :aria-valuenow="loading"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    class="h-10 rounded-full bg-purple-500 absolute"
                                    style="width: 0%;"
                                ></div>
                            </div>
                        </li>
                    </ul>
                    <div class="flex items-center justify-between mt-40">
                        <Button
                            @btnClick="deleteAllUploadedFiles(uploadedFiles)"
                            class="button bg-gray-500 hover:bg-gray-600 text-white w-auto px-30"
                            role="button"
                            :text="$t('cancelButtonText')"
                            :disabled="deleteFileFromModalLoading"
                            :spinning="deleteFileFromModalLoading"
                        />
                        <Button
                            @btnClick="closeModalAfterUpload"
                            class="button bg-purple-500 hover:bg-purple-600 text-white w-auto px-30"
                            role="button"
                            :text="$t('saveButtonText')"
                            :disabled="waitForFilesToLoad"
                        />
                    </div>
                </div>
            </div>
        </Modal>
        <Modal
            width='w-768'
            v-if="showFromsModal"
            @close="showFromsModal = false"
            @closeOnEscPressed="showFromsModal = false"
            class="px-30 md:px-0"
        >
            <div slot="title">
                <h2 class="font-4xl font-titillium-bold opacity-85 text-gray-800">{{$t('addDocumentTitle')}}</h2>
            </div>
            <p
                class="my-30 p tracking-tight opacity-85 text-gray-800"
                slot="subtitle"
            >
                {{ $t('addDocumentSubitle') }}
            </p>
            <div
                slot="body"
                class="bg-gray-200 p-30 rounded"
            >
                <div>
                    <Button
                        @btnClick="back"
                        class="button bg-gray-500 hover:bg-gray-600 text-white w-auto px-30 mb-30"
                        role="button"
                        :text="$t('backButtonText')"
                    />
                </div>
                <div
                    v-for="(file, index) in publishedForms"
                    :key="index"
                >
                    <span class="flex items-center mb-10" style="cursor:pointer;"
                        @click="closeWindow(file.id, caseDetails.id)"
                    >
                        <img
                            :src="require('@/assets/images/icons/form.svg')"
                            alt=""
                        >
                        <div
                            style="text-decoration: underline;"
                            class="ml-10 text-purple-600"
                        >{{file.name}}</div>
                    </span>
                </div>
            </div>
        </Modal>
        <Modal
            width='w-420'
            v-if="showDeleteModal"
            @close="showDeleteModal = false"
            @closeOnEscPressed="showDeleteModal = false"
        >
            <div slot="title">
                <h2 class="font-4xl font-titillium-bold opacity-85 text-gray-800">{{ $t('deleteFileTitle') }}</h2>
            </div>
            <p
                class="my-30 p tracking-tight opacity-85 text-gray-800"
                slot="subtitle"
            >
                {{ $t('deleteFileSubtitle') }} {{fileDetail.name}}?
            </p>
            <div slot="body">
                <div class="grid grid-cols-2 gap-30">
                    <Button
                        @btnClick="showDeleteModal = false"
                        class="button bg-gray-500 hover:bg-gray-600 text-white w-full px-30 mb-30"
                        role="button"
                        :text="$t('noButtonText')"
                    />
                    <Button
                        @btnClick="deleteFileFromTable(fileDetail)"
                        class="button bg-red-500 hover:bg-red-600 text-white w-full px-30 mb-30"
                        role="button"
                        :text="$t('yesButtonText')"
                        :disabled="deleteFileFromTableLoading"
                        :spinning="deleteFileFromTableLoading"
                    />
                </div>
            </div>
        </Modal>
        <Modal
            width='w-420'
            v-if="showServiceDeleteModal"
            @close="showServiceDeleteModal = false"
            @closeOnEscPressed="showServiceDeleteModal = false"
        >
            <div slot="title">
                <h2 class="font-4xl text-gray-800 font-titillium-bold opacity-85">{{ $t('deleteServiceText') }}</h2>
            </div>
            <p
                class="my-30 p text-gray-800"
                slot="subtitle"
            >
                <!-- {{ $t('deleteMemberSubtitle') }} {{member.name}}? -->
            </p>
            <div slot="body">
                <div class="grid grid-cols-2 gap-30">
                    <Button
                        @btnClick="showServiceDeleteModal = false"
                        class="button bg-gray-500 hover:bg-gray-600 text-white w-full px-30 mb-30"
                        role="button"
                        :text="$t('deleteServiceNoButtonText')"
                    />
                    <Button
                        @btnClick="onDeleteServiceAllocation(srv)"
                        class="button bg-red-500 hover:bg-red-600 text-white w-full px-30 mb-30"
                        role="button"
                        :text="$t('deleteServiceYesButtonText')"
                        :disabled="serviceDeleteLoading"
                        :spinning="serviceDeleteLoading"
                    />
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import VictimDetails from '@/views/dashboard/cases/caseDetailsComponents/VictimDetails'
import AggressorDetails from '@/views/dashboard/cases/caseDetailsComponents/AggressorDetails'
import ResponsiblesList from '@/views/dashboard/cases/caseDetailsComponents/ResponsiblesList'
import Button from '@/components/buttons/Button'
import Input from '@/components/form/Input'
import Multiselect from 'vue-multiselect'
import Modal from '@/components/modal/Modal'
import SuccessBox from '@/components/success-message/SuccessBox'

export default {
    name: 'CaseDetails',
    props: ['slug', 'showConfirmationBox'],
    components: {
        VictimDetails,
        AggressorDetails,
        ResponsiblesList,
        Button,
        Multiselect,
        Input,
        Modal
    },
    data () {
        return {
            fileIsUpload: false,
            filesToBeUploaded: [],
            showModal: false,
            showFromsModal: false,
            showDeleteModal: false,
            showServiceDeleteModal: false,
            // Edit Service table row
            editMode: false,
            editedServiceRow: null,
            updateDone: false,
            addService: false,
            caseDetails: {
                aggressor: {
                    adults_dependents_number: 0,
                    age: 0,
                    children_dependents_number: 0,
                    citizenships: [],
                    criminal_records: '',
                    current_residence: {
                        address: '',
                        city: {},
                        county: {},
                        type: null
                    },
                    domestic_violence_cases: [],
                    elderly_dependents_number: 0,
                    family_doctor_name: '',
                    family_doctor_phone: '',
                    first_name: '',
                    has_criminal_records: 0,
                    has_dependents: 0,
                    has_domestic_violence: 0,
                    has_family_doctor: 0,
                    has_psychiatric_history: 0,
                    has_substance_use: 0,
                    income_range: '',
                    job: '',
                    last_name: '',
                    legal_residence: {
                        address: '',
                        city: {},
                        county: {},
                        type: null
                    },
                    marital_status: '',
                    nationality: '',
                    occupations: [],
                    phone: '',
                    pid: '',
                    psychiatric_history: '',
                    religion: '',
                    school_level: '',
                    substance_use_cases: [],
                    teenagers_dependents_number: 0
                },
                consent: 0,
                custom_relationship_type: '',
                files: [],
                forensic_certificate_number: '',
                has_forensic_certificate: 0,
                has_hospitalization: 0,
                has_medical_care: 0,
                has_medical_leave: 0,
                hospitalization_days_number: 0,
                medical_care_days_number: 0,
                medical_leave_days_number: 0,
                modified: '',
                registered: '',
                relationship_type: '',
                responsibles: [],
                services: [],
                status: '',
                victim: {
                    adults_dependents_number: 0,
                    age: null,
                    children_dependents_number: 0,
                    citizenships: [],
                    criminal_records: '',
                    current_residence: {
                        address: '',
                        city: {},
                        county: {},
                        type: null
                    },
                    domestic_violence_cases: [],
                    elderly_dependents_number: 0,
                    family_doctor_name: '',
                    family_doctor_phone: '',
                    first_name: '',
                    has_criminal_records: 0,
                    has_dependents: 0,
                    has_domestic_violence: 0,
                    has_family_doctor: 0,
                    has_psychiatric_history: 0,
                    has_substance_use: 0,
                    income_range: '',
                    job: '',
                    last_name: '',
                    legal_residence: {
                        address: '',
                        city: {},
                        county: {},
                        type: null
                    },
                    marital_status: '',
                    nationality: '',
                    occupations: [],
                    phone: '',
                    pid: '',
                    psychiatric_history: '',
                    religion: '',
                    school_level: '',
                    substance_use_cases: [],
                    teenagers_dependents_number: 0
                }
            },
            addservices: [{
                service: '',
                responsibles: '',
                amount: '',
                count: 0
            }],
            count: 0,
            loading: 0,
            fileDetail: '',
            arr: [],
            hoursDoneUpdateNumber: '',

            currentPageServices: 1,
            totalPagesServices: 0,
            pageSizeServices: 10,
            pageServicesSizeIncrement: null,
            currentServicesPageSize: null,
            pageServices: null,

            currentPageFiles: 1,
            totalPagesFiles: 0,
            pageSizeFiles: 10,
            pageFilesSizeIncrement: null,
            currentFilesPageSize: null,
            pageFiles: null,

            updateAllocatedServiceLoading: false,
            updateHoursDoneLoading: false,
            addServicesToCaseLoading: false,
            downloadAllLoading: false,
            deleteFileFromTableLoading: false,
            archiveCaseLoading: false,
            unarchiveCaseLoading: false,
            srv: '',
            serviceDeleteLoading: false,
            deleteFileFromModalLoading: false,

            waitForFilesToLoad: true
        }
    },
    computed: {
        ...mapGetters(['cases', 'user', 'error', 'errors', 'userTypeManager', 'userTypeStaff', 'availableServices', 'token', 'requestStatus', 'publishedForms', 'progress', 'uploadedFiles', 'allMembers'])
    },
    methods: {
        ...mapActions(['updateHoursDone', 'getAvailableServices', 'addServiceAllocations', 'editServiceAllocation', 'deleteServiceAllocation', 'eraseErrors', 'archiveCase', 'unarchiveCase', 'fetchProfile', 'uploadFiles', 'getCases', 'getPublishedForms', 'deleteFile', 'downloadAllFiles', 'downloadOneFile', 'clearUploadedFiles', 'getMembers']),
        name (row) {
            return row.name
        },
        closeWindow (fileId, caseId) {
            this.$router.push({ name: 'FormPreview', params: { slug: `${fileId}` }, query: { case_id: `${caseId}` } })
            window.close()
        },
        editFromPdf (fileId, caseId) {
            this.$router.push({ name: 'FormPreview', params: { slug: `${fileId}` }, query: { case_id: `${caseId}`, type: 'edit' } })
            window.close()
        },
        addServices () {
            this.addService = !this.addService
            this.addservices = [{
                service: '',
                responsibles: '',
                amount: '',
                count: 0
            }]
            this.count = 0
        },
        addAnother () {
            this.addservices.push({
                service: '',
                responsibles: '',
                amount: '',
                count: ++this.count
            })
        },
        removeField (index) {
            if (index > 0) {
                this.addservices.splice(index, 1)
            }
        },

        /** Allocate services to the case */
        addServicesToCase () {
            this.addServicesToCaseLoading = true

            const SuccessBoxComponent = Vue.extend(SuccessBox)

            const servicesToAllocate = {
                case_id: this.caseDetails.id,
                services: []
            }

            const servicesForBackend = new Map()

            /** We traverse the whole array of services allocations to check for duplicates */
            for (const service of this.addservices) {
                /**
                 * If the current map has the object, then merge it with the existing one
                 * Otherwise, add it as a new one.
                 * The responsibles array is merged also to not have any duplicate responsibles.
                 * The amounts are summed.
                 */
                if (servicesForBackend.has(service.service.id)) {
                    servicesForBackend.get(service.service.id).amount = +servicesForBackend.get(service.service.id).amount + +service.amount
                    for (const responsible of service.responsibles) {
                        if (servicesForBackend.get(service.service.id).responsibles.findIndex(responsibleBackend => responsible.id === responsibleBackend.id) === -1) {
                            servicesForBackend.get(service.service.id).responsibles.push({ id: responsible.id })
                        }
                    }
                } else {
                    servicesForBackend.set(service.service.id, {
                        service_id: service.service.id,
                        responsibles: service.responsibles ? service.responsibles.map(responsible => ({ id: responsible.id })) : [{ id: '' }],
                        amount: +service.amount,
                        type: service.service.type
                    })
                }
            }

            servicesToAllocate.services = Array.from(servicesForBackend.values())

            this.addServiceAllocations({ token: this.token, services: servicesToAllocate })
                .then(response => {
                    if (this.requestStatus === true) {
                        this.addServicesToCaseLoading = false
                        this.addServices()

                        /** Dynamically mount success box to page */
                        const instance = new SuccessBoxComponent({
                            propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('newAllocationText'), success: true }
                        })
                        instance.$mount()
                        this.$refs.successBoxContainer.appendChild(instance.$el)

                        this.getAvailableServices({ token: this.token, case_id: this.caseDetails.id })
                    } else {
                        this.addServicesToCaseLoading = false
                        if (this.error !== '') {
                            const instance = new SuccessBoxComponent({
                                propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                            })
                            instance.$mount()
                            this.$refs.successBoxContainer.appendChild(instance.$el)
                        }
                    }
                })
        },
        /** edit allocated case */
        onSubmit (id, name, responsibles, allocatedAmount, reportedAmount, measurementUnit, canEdit, total, type) {
            this.updateAllocatedServiceLoading = true

            const SuccessBoxComponent = Vue.extend(SuccessBox)

            const editAllocation = {
                case_id: this.caseDetails.id,
                allocation: {
                    id: id,
                    amount: allocatedAmount,
                    responsibles: (responsibles.length > 0) ? (responsibles.map(responsible => ({ id: responsible.id }))) : ([{ id: '' }])
                },
                allocationForFrontend: {
                    id: id,
                    name: name,
                    responsibles: responsibles,
                    allocated_amount: allocatedAmount,
                    reported_amount: reportedAmount,
                    measurement_unit: measurementUnit,
                    total: total,
                    can_edit: canEdit,
                    type: type
                }
            }

            this.editServiceAllocation({ token: this.token, allocation: editAllocation })
                .then(response => {
                    if (this.requestStatus === true) {
                        this.updateAllocatedServiceLoading = false
                        this.viewServiceRowData()

                        /** Dynamically mount success box to page */
                        const instance = new SuccessBoxComponent({
                            propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('editAllocationText'), success: true }
                        })
                        instance.$mount()
                        this.$refs.successBoxContainer.appendChild(instance.$el)
                    } else {
                        this.updateAllocatedServiceLoading = false
                        if (this.error !== '') {
                            const instance = new SuccessBoxComponent({
                                propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                            })
                            instance.$mount()
                            this.$refs.successBoxContainer.appendChild(instance.$el)
                        }
                    }
                })
        },
        // Edit service row data method
        editServiceRowData (service) {
            this.beforEditCache = service
            this.editedServiceRow = service
            this.eraseErrors()
        },
        // Edit service row data method
        viewServiceRowData (service) {
            this.beforEditCache = null
            this.editedServiceRow = null
            this.updateDone = false
        },
        editServiceDoneData (service) {
            this.editedServiceRow = service
            this.updateDone = true
        },

        /** Fuction to update the number of hours conducted of an allocated service. */
        onUpdateHoursDone (id) {
            this.updateHoursDoneLoading = true
            const SuccessBoxComponent = Vue.extend(SuccessBox)

            const editAllocation = {
                case_id: this.caseDetails.id,
                allocation: {
                    case_id: this.caseDetails.id,
                    allocation_id: id,
                    value: +this.hoursDoneUpdateNumber
                },
                allocationForFrontend: {
                    id: id,
                    name: this.editedServiceRow.name,
                    responsibles: this.editedServiceRow.responsibles,
                    allocated_amount: this.editedServiceRow.allocated_amount,
                    reported_amount: +this.editedServiceRow.reported_amount + +this.hoursDoneUpdateNumber,
                    measurement_unit: this.editedServiceRow.measurement_unit,
                    total: this.editedServiceRow.total,
                    can_edit: this.editedServiceRow.can_edit,
                    type: this.editedServiceRow.type
                }
            }

            this.updateHoursDone({ token: this.token, allocation: editAllocation })
                .then(response => {
                    if (this.requestStatus === true) {
                        this.updateHoursDoneLoading = false

                        this.viewServiceRowData()

                        /** Dynamically mount success box to page */
                        const instance = new SuccessBoxComponent({
                            propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('doneUpdateMessage'), success: true }
                        })
                        instance.$mount()
                        this.$refs.successBoxContainer.appendChild(instance.$el)
                    } else {
                        this.updateHoursDoneLoading = false

                        if (this.error !== '') {
                            const instance = new SuccessBoxComponent({
                                propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                            })
                            instance.$mount()
                            this.$refs.successBoxContainer.appendChild(instance.$el)
                        }
                    }
                })
        },

        openDeleteServiceModal (service) {
            this.srv = service
            this.showServiceDeleteModal = true
        },

        /** Function to delete an allocated service */
        onDeleteServiceAllocation (srv) {
            const SuccessBoxComponent = Vue.extend(SuccessBox)

            const allocation = {
                case_id: this.caseDetails.id,
                id: srv.id
            }

            this.serviceDeleteLoading = true

            this.deleteServiceAllocation({ token: this.token, allocation: allocation })
                .then(response => {
                    if (this.requestStatus === true) {
                        /** Dynamically mount success box to page */
                        const instance = new SuccessBoxComponent({
                            propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('deleteAllocationText'), success: true }
                        })
                        instance.$mount()
                        this.$refs.successBoxContainer.appendChild(instance.$el)
                        this.showServiceDeleteModal = false
                        this.serviceDeleteLoading = false

                        this.getAvailableServices({ token: this.token, case_id: this.caseDetails.id })
                    } else {
                        if (this.error !== '') {
                            const instance = new SuccessBoxComponent({
                                propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                            })
                            instance.$mount()
                            this.$refs.successBoxContainer.appendChild(instance.$el)
                            this.showServiceDeleteModal = false
                            this.serviceDeleteLoading = false
                        }
                    }
                })
        },

        /** Function to archive one case. */
        onArchiveCase () {
            this.archiveCaseLoading = true
            const SuccessBoxComponent = Vue.extend(SuccessBox)

            const caseToArchive = {
                case_id: this.slug
            }
            this.archiveCase({ token: this.token, caseToArchive: caseToArchive })
                .then(response => {
                    if (this.requestStatus === true) {
                        this.archiveCaseLoading = false

                        /** Dynamically mount success box to page */
                        const instance = new SuccessBoxComponent({
                            propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('archiveCaseText'), success: true }
                        })
                        instance.$mount()
                        this.$refs.successBoxContainer.appendChild(instance.$el)
                    } else {
                        this.archiveCaseLoading = false

                        if (this.error !== '') {
                            const instance = new SuccessBoxComponent({
                                propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                            })
                            instance.$mount()
                            this.$refs.successBoxContainer.appendChild(instance.$el)
                        }
                    }
                })
        },

        /** Function to unarchive one case. */
        onUnarchiveCase () {
            this.unarchiveCaseLoading = true

            const SuccessBoxComponent = Vue.extend(SuccessBox)

            const caseToUnarchive = {
                case_id: this.slug
            }
            this.unarchiveCase({ token: this.token, caseToUnarchive: caseToUnarchive })
                .then(response => {
                    if (this.requestStatus === true) {
                        this.unarchiveCaseLoading = false

                        /** Dynamically mount success box to page */
                        const instance = new SuccessBoxComponent({
                            propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('unarchiveCaseText'), success: true }
                        })
                        instance.$mount()
                        this.$refs.successBoxContainer.appendChild(instance.$el)
                    } else {
                        this.unarchiveCaseLoading = false

                        if (this.error !== '') {
                            const instance = new SuccessBoxComponent({
                                propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                            })
                            instance.$mount()
                            this.$refs.successBoxContainer.appendChild(instance.$el)
                        }
                    }
                })
        },

        /** Upload single or multiple files files. */
        fileUpload () {
            this.waitForFilesToLoad = true
            const SuccessBoxComponent = Vue.extend(SuccessBox)

            this.filesToBeUploaded = [...this.$refs.file.files]
            /** Loop over the uploaded files. */
            for (let i = 0; i < this.filesToBeUploaded.length; i++) {
                const file = this.filesToBeUploaded[i]

                /** Save uploaded files one at a time. */
                this.uploadFiles({
                    token: this.token,
                    case_id: this.caseDetails.id,
                    file: file,
                    progressBarId: 'fileProgressBar-' + i
                }).then(response => {
                    if (this.requestStatus === true) {
                        this.waitForFilesToLoad = false
                        /** Dynamically mount success box to page */
                        const instance = new SuccessBoxComponent({
                            propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('addCaseFileText'), success: true }
                        })
                        instance.$mount()
                        this.$refs.successBoxContainer.appendChild(instance.$el)
                    } else {
                        if (this.error !== '') {
                            const instance = new SuccessBoxComponent({
                                propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                            })
                            instance.$mount()
                            this.$refs.successBoxContainer.appendChild(instance.$el)
                        }
                    }
                    this.fileIsUpload = true
                })
            }
            this.loading = 0
        },
        dragover (event) {
            event.preventDefault()
        },
        /** Upload files on drag and drop. */
        drop (event) {
            event.preventDefault()
            this.$refs.file.files = event.dataTransfer.files
            this.fileUpload()
        },
        /** Open confirmation modal for deleting file. */
        openDeleteModal (item) {
            this.fileDetail = item
            this.showDeleteModal = true
        },

        /** Delete uploaded file from table. */
        deleteFileFromTable (item) {
            this.deleteFileFromTableLoading = true

            const SuccessBoxComponent = Vue.extend(SuccessBox)

            this.deleteFile({ token: this.token, case_id: this.caseDetails.id, file_id: item.id })
                .then(() => {
                    this.showDeleteModal = false
                    if (this.requestStatus === true) {
                        this.deleteFileFromTableLoading = false

                        /** Dynamically mount success box to page */
                        const instance = new SuccessBoxComponent({
                            propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('deleteCaseFileText'), success: true }
                        })
                        instance.$mount()
                        this.$refs.successBoxContainer.appendChild(instance.$el)
                    } else {
                        this.deleteFileFromTableLoading = false

                        if (this.error !== '') {
                            const instance = new SuccessBoxComponent({
                                propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                            })
                            instance.$mount()
                            this.$refs.successBoxContainer.appendChild(instance.$el)
                        }
                    }
                })
        },

        /** Delete uploaded file from modal. */
        deleteUploadedFile (index) {
            const SuccessBoxComponent = Vue.extend(SuccessBox)

            this.deleteFile({ token: this.token, case_id: this.caseDetails.id, file_id: this.uploadedFiles[index].id, array_index: index })
                .then(() => {
                    /** Remove item at specified index. */
                    this.filesToBeUploaded.splice(index, 1)

                    if (this.requestStatus === true) {
                        /** Dynamically mount success box to page */
                        const instance = new SuccessBoxComponent({
                            propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('deleteCaseFileText'), success: true }
                        })
                        instance.$mount()
                        this.$refs.successBoxContainer.appendChild(instance.$el)
                    } else {
                        if (this.error !== '') {
                            const instance = new SuccessBoxComponent({
                                propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                            })
                            instance.$mount()
                            this.$refs.successBoxContainer.appendChild(instance.$el)
                        }
                    }
                })
        },

        /** Function to delete all uploded files. */
        deleteAllUploadedFiles (filesToDelete) {
            this.deleteFileFromModalLoading = true
            const SuccessBoxComponent = Vue.extend(SuccessBox)

            for (let i = 0; i < filesToDelete.length; i++) {
                this.deleteFile({ token: this.token, case_id: this.caseDetails.id, file_id: filesToDelete[i].id })
                    .then(() => {
                        if (this.requestStatus === true) {
                            this.deleteFileFromModalLoading = false
                            this.filesToBeUploaded = []

                            /** Dynamically mount success box to page */
                            const instance = new SuccessBoxComponent({
                                propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('deleteCaseFileText'), success: true }
                            })
                            instance.$mount()
                            this.$refs.successBoxContainer.appendChild(instance.$el)
                        } else {
                            this.deleteFileFromModalLoading = false
                            if (this.error !== '') {
                                const instance = new SuccessBoxComponent({
                                    propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                                })
                                instance.$mount()
                                this.$refs.successBoxContainer.appendChild(instance.$el)
                            }
                        }
                    })
            }

            this.clearUploadedFiles()
        },
        closeModalAfterUpload () {
            this.filesToBeUploaded = []

            this.caseDetails.files.unshift(...this.uploadedFiles)

            this.clearUploadedFiles()

            this.showModal = false
        },
        closeAddFilesModal () {
            this.filesToBeUploaded = []
            this.showModal = false
        },
        /** Open modal for adding already created form. */
        OpenSablonForms () {
            this.showModal = false
            this.showFromsModal = true
        },
        back () {
            this.showModal = true
            this.showFromsModal = false
        },
        /** Download all files from table as zip. */
        downloadAll () {
            this.downloadAllLoading = true

            this.downloadAllFiles({
                token: this.token,
                case_id: this.caseDetails.id,
                archive_name: this.caseDetails.id
            })
                .then(() => {
                    this.downloadAllLoading = false
                })
        },
        /** Download one file from table. */
        downloadOne (item) {
            this.downloadOneFile({
                token: this.token,
                case_id: this.caseDetails.id,
                file_id: item.id,
                file_name: item.download_name
            })
        },

        /** Display info table in table footer method for allocated services */
        getTableRecordsInfoServices () {
            this.currentServicesPageSize = document.getElementById('table_body')?.childElementCount
            this.pageServices = (this.pageSizeServices * (this.currentPageServices - 1)) + 1

            if (this.caseDetails.services.length < (this.pageSizeServices * this.currentPageServices)) {
                this.pageServicesSizeIncrement = (this.pageSizeServices * (this.currentPageServices - 1)) + this.currentServicesPageSize
            } else {
                this.pageServicesSizeIncrement = (this.pageSizeServices * this.currentPageServices)
            }
        },

        /** Display info table in table footer method for files */
        getTableRecordsInfoFiles () {
            this.currentFilesPageSize = document.getElementById('doc_table_body')?.childElementCount
            this.pageFiles = (this.pageSizeFiles * (this.currentPageFiles - 1)) + 1

            if (this.caseDetails.files.length < (this.pageSizeFiles * this.currentPageFiles)) {
                this.pageFilesSizeIncrement = (this.pageSizeFiles * (this.currentPageFiles - 1)) + this.currentFilesPageSize
            } else {
                this.pageFilesSizeIncrement = (this.pageSizeFiles * this.currentPageFiles)
            }
        }
    },
    mounted () {
        this.getCases({ token: this.token }).then(async response => {
            this.caseDetails = this.cases.find((caseDetails) => `${caseDetails.id}` === this.slug)
            /** Extract all forms form api. */
            await this.getPublishedForms({ token: this.token, case_id: this.caseDetails.id })
            await this.fetchProfile({ token: this.token })
            await this.getAvailableServices({ token: this.token, case_id: this.caseDetails.id }).then(() => {
                this.getTableRecordsInfoServices()
            })
            if (this.userTypeManager) {
                await this.getMembers({ token: this.token })
            }
            this.getTableRecordsInfoFiles()
        })

        if (this.showConfirmationBox) {
            const SuccessBoxComponent = Vue.extend(SuccessBox)
            /** Dynamically mount success box to page */
            const instance = new SuccessBoxComponent({
                propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('editCaseMessage'), success: true }
            })
            instance.$mount()
            this.$refs.successBoxContainer.appendChild(instance.$el)
        }
    },
    updated () {
        this.getTableRecordsInfoServices()
        this.getTableRecordsInfoFiles()
    }
}
</script>
<style>
[v-cloak] {
    display: none;
}
.edit {
    display: none;
}
.editing .edit {
    display: block;
}
.editing .view {
    display: none;
}
</style>
