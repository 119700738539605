<template>
    <div class="bg-white rounded-lg p-20 mb-30">
        <!-- Aggressor type -->
        <div class="mb-22">
            <span class="rounded-full bg-gray-200 text-gray-800 px-12 small py-4">{{ $t('caseDetailsAgresorTagText') }}</span>
        </div>
        <!-- Aggressor name -->
        <!-- Not used anymore -->
        <!-- <div class="mb-22">
            <p
                v-if="caseDetails.aggressor.first_name || caseDetails.aggressor.last_name"
                class="text-2xl tracking-tight opacity-85 text-bold font-titillium-bold text-gray-800"
            >
                {{ caseDetails.aggressor.first_name }}&nbsp;{{ caseDetails.aggressor.last_name }}
            </p>
            <p
                class="p tracking-tight opacity-85 text-gray-700"
                v-else
            >{{$t('caseDetailsNoData')}}
            </p>
        </div> -->
        <!-- Aggressor phone -->
        <!-- Not used anymore -->
        <!-- <div class="flex items-center mb-22">
            <img
                class="mr-22"
                :src="require('@/assets/images/icons/phone.svg')"
                alt=""
            >
            <div class="flex flex-col">
                <p class="font-lg-bold tracking-tight opacity-85 text-gray-700">{{ $t('caseDetailsPhoneText') }}</p>
                <p
                    v-if="caseDetails.aggressor.phone"
                    class="p tracking-tight opacity-85 text-gray-700"
                >{{ caseDetails.aggressor.phone }}</p>
                <p
                    class="p tracking-tight opacity-85 text-gray-700"
                    v-else
                >{{$t('caseDetailsNoData')}}
                </p>
            </div>
        </div> -->
        <!-- Aggressor relation with victim -->
        <div class="flex items-center mb-22">
            <img
                class="mr-22"
                :src="require('@/assets/images/icons/attach.svg')"
                alt=""
            >
            <div class="flex flex-col">
                <p class="font-lg-bold tracking-tight opacity-85 text-gray-700">{{ $t('caseDetailsBullyVictimRelation') }}</p>
                <p
                    v-if="caseDetails.relationship_type.name"
                    class="p tracking-tight opacity-85 text-gray-700"
                >{{ caseDetails.relationship_type.name }}</p>
                <p
                    class="p tracking-tight opacity-85 text-gray-700"
                    v-else
                >{{$t('caseDetailsNoData')}}
                </p>
            </div>
        </div>
        <!-- Aggressor psihic status -->
        <div class="flex items-center mb-22">
            <img
                class="mr-22"
                :src="require('@/assets/images/icons/justice.svg')"
                alt=""
            >
            <div class="flex flex-col">
                <p class="font-lg-bold tracking-tight opacity-85 text-gray-700">{{ $t('caseDetailsPreviousPsihicConsult') }}</p>
                <p
                    class="p tracking-tight opacity-85 text-gray-700"
                    v-if="caseDetails.aggressor.has_psychiatric_history"
                >
                    {{caseDetails.aggressor.psychiatric_history}}
                </p>
                <p
                    class="p tracking-tight opacity-85 text-gray-700"
                    v-else
                >{{$t('caseDetailsNoData')}}</p>
            </div>
        </div>
        <!-- Aggressor criminal record -->
        <div class="flex items-center mb-22">
            <img
                class="mr-22"
                :src="require('@/assets/images/icons/justice.svg')"
                alt=""
            >
            <div class="flex flex-col">
                <p class="font-lg-bold tracking-tight opacity-85 text-gray-700">{{ $t('caseDetailsPreviousJusticeRecord') }}</p>
                <p
                    class="p tracking-tight opacity-85 text-gray-700"
                    v-if="caseDetails.aggressor.has_criminal_records"
                >
                    {{ caseDetails.aggressor.criminal_records }}
                </p>
                <p
                    class="p tracking-tight opacity-85 text-gray-700"
                    v-else
                >{{$t('caseDetailsNoData')}}</p>
            </div>
        </div>
        <!-- Aggressor substance use -->
        <div class="flex items-center mb-22">
            <img
                class="mr-22"
                :src="require('@/assets/images/icons/hand.svg')"
                alt=""
            >
            <div class="flex flex-col">
                <p class="font-lg-bold tracking-tight opacity-85 text-gray-700">{{ $t('caseDetailsIlegalSubstances') }}</p>
                <p
                    class="p tracking-tight opacity-85 text-gray-700"
                    v-if="caseDetails.aggressor.has_substance_use"
                >
                    {{ $t('yesText') }}
                </p>
                <p
                    class="p tracking-tight opacity-85 text-gray-700"
                    v-else
                >{{$t('caseDetailsNoData')}}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AggressorDetails',
    props: ['caseDetails']
}
</script>
